import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import styled from 'styled-components';

import * as CONFIG from '../../SiteConfig';

import DotPattern from '../assets/images/patterns/dot-grid.png';
import PatternDark from '../assets/images/patterns/escheresque_ste.png';

import ApplicationContext from '../components/particles/context/ApplicationContext';
import * as ROUTES from '../components/particles/constants/routes';

import Layout from '../components/templates/Default';

const IndexWrapper = styled.section`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%:
  justify-content: center;
  max-width: 100%;
  padding: 64px 0;
  width: 100%;

  background-color: ${props =>
    props.mode === 'dark' ? props.theme.black : props.theme.white};
  background-image: url(' ${props =>
    props.mode === 'dark' ? PatternDark : DotPattern} ');
  color: ${props =>
    props.mode === 'dark' ? props.theme.white : props.theme.black};

  .h3 {
    margin-bottom: 32px;

    text-transform: initial;
  }

  > * {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    width: 100%;

    text-align: left;
  }

    /* IE Fix */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;

    > * {
      max-width: 1000px;
    }
  }
`;

const LandingPage = () => {
  const { state } = useContext(ApplicationContext);

  return (
    <IndexWrapper mode={state.theme}>
      <h1 className="h3">
       Welcome to the Environment Centre (tEC) Mapping Tool
      </h1>
     
      <p>
        Please{' '}
        <Link to={ROUTES.SIGN_IN}>log in here</Link>. If you do not have an account, the following username and password can be used:
        
      </p>
      <p> Username: enquiries@environmentcentre.com
      </p> 
      <p> Password: tECmaps2020
      </p>
    </IndexWrapper>
  );
};

export default () => (
  <Layout align="center" page="landing">
    <Helmet
      title={`${CONFIG.SITE_TITLE} | Landing Page`}
      defer={false}
    />
    <LandingPage />
  </Layout>
);
